import React from "react";
import MainContainer from "./Navigation/Pages/MainContainer";
import { AuthProvider } from "./Context/Authcontext";
import "./App.css";

function App() {
  return (
    <AuthProvider>
      <MainContainer />
    </AuthProvider>
  );
}

export default App;
