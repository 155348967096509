import React, { useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import AuthContext from "../../Context/Authcontext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavBar from "../Components/NavBar";
import MainContent from "../Components/MainContent";
import Login from "../../Auth/Login";
import Signup from "../../Accounts/Signup";
import ForgotPassword from "../../Auth/ForgotPassword";

function MainContainer() {
  const { state, signin } = useContext(AuthContext);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("UserData"));
    if (storedData && storedData.token) {
      signin(storedData.userId, storedData.token, storedData.role);
    }
  }, []);

  if (!state.isLoggedIn) {
    return (
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col xs={2} lg={2} className="nav-bar">
            <NavBar />
          </Col>
          <Col xs={10} lg={10}>
            <Row className="justify-content-center">
              <MainContent />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MainContainer;
