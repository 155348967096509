import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts/passwordreset/`,
        data: {
          email: email,
        },
      });
      toast("Se te envió un correo con una contraseña temporal");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="justify-content-center">
      <Col lg={8} className="forgot-password">
        <ToastContainer />
        <h1>Reestablecer contraseña</h1>
        <Form>
          <Form.Group className="mb-3">
            <Form.Control
              type="email"
              placeholder="Ingresar el correo con el que te registraste"
              onChange={handleEmailChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Button
              variant="primary"
              onClick={handleSubmit}
              style={{ marginRight: 10 }}
            >
              Reestablecer contraseña
            </Button>
            <NavLink to="/login">
              <Button variant="danger">Cancelar</Button>
            </NavLink>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
}

export default ForgotPassword;
