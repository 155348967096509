import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState({
    token: null,
    userId: null,
    role: null,
    isLoggedIn: false,
  });

  const navigate = useNavigate();

  const signin = async (userId, token, role) => {
    setState({
      token: token,
      userId: userId,
      role: role,
      isLoggedIn: true,
    });

    localStorage.setItem(
      "UserData",
      JSON.stringify({
        token: token,
        userId: userId,
        role: role,
      })
    );

    navigate("/dashboard");
  };

  const signout = () => {
    setState({ token: null, userId: null, role: null, isLoggedIn: false });

    localStorage.removeItem("UserData");
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        signin,
        signout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
