import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import {
  FaSearch,
  FaHome,
  FaTh,
  FaRegListAlt,
  FaWaveSquare,
  FaSignOutAlt,
  FaUserCircle,
} from "react-icons/fa";
import AuthContext from "../../Context/Authcontext";
import Image from "react-bootstrap/Image";
import Logo from "../../Static/Images/Sphere_logo_login.png";

function NavBar() {
  const { state, signout } = useContext(AuthContext);

  if (state.role === "admin") {
    return (
      <Nav defaultActiveKey="/home" className="flex-column">
        <Nav.Item className="center">
          <Image src={Logo} />
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/my-account">
            <FaUserCircle className="nav-bar-icon" />
            Mi Cuenta
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/dashboard">
            <FaHome className="nav-bar-icon" />
            Mis Perfiles
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/search">
            <FaSearch className="nav-bar-icon" />
            Búsqueda
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink to="/competencies">
            <FaRegListAlt className="nav-bar-icon" />
            Competencias
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/behaviors">
            <FaWaveSquare className="nav-bar-icon" />
            Comportamientos
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/profiles">
            <FaTh className="nav-bar-icon" />
            Perfiles
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink onClick={signout} to="/login">
            <FaSignOutAlt className="nav-bar-icon" />
            Salir
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  } else {
    return (
      <Nav defaultActiveKey="/home" className="flex-column">
        <Nav.Item className="center">
          <Image src={Logo} />
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/my-account">
            <FaUserCircle className="nav-bar-icon" />
            Mi Cuenta
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/dashboard">
            <FaHome className="nav-bar-icon" />
            Mis perfiles
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/search">
            <FaSearch className="nav-bar-icon" />
            Búsqueda
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink onClick={signout} to="/login">
            <FaSignOutAlt className="nav-bar-icon" />
            Salir
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  }
}

export default NavBar;
