import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Col from "react-bootstrap/Col";
const Login = React.lazy(() => import("../../Auth/Login"));
const Dashboard = React.lazy(() => import("../../Dashboard/Pages/Dashboard"));
const Search = React.lazy(() => import("../../Search/Pages/Search"));
const Competencies = React.lazy(() =>
  import("../../Competencies/Pages/Competencies")
);
const NewCompetency = React.lazy(() =>
  import("../../Competencies/Pages/NewCompetency")
);
const EditCompetency = React.lazy(() =>
  import("../../Competencies/Pages/EditCompetency")
);
const DeleteCompetency = React.lazy(() =>
  import("../../Competencies/Pages/DeleteCompetency")
);
const Behaviors = React.lazy(() => import("../../Behaviors/Pages/Behaviors"));
const NewBehavior = React.lazy(() =>
  import("../../Behaviors/Pages/NewBehavior")
);
const EditBehavior = React.lazy(() =>
  import("../../Behaviors/Pages/EditBehavior")
);
const DeleteBehavior = React.lazy(() =>
  import("../../Behaviors/Pages/DeleteBehavior")
);
const Profiles = React.lazy(() => import("../../Profiles/Pages/Profiles"));
const NewProfile = React.lazy(() => import("../../Profiles/Pages/NewProfile"));
const EditProfile = React.lazy(() =>
  import("../../Profiles/Pages/EditProfile")
);
const AddIndicators = React.lazy(() =>
  import("../../Profiles/Pages/AddIndicators")
);
const ProfileDetails = React.lazy(() =>
  import("../../Profiles/Pages/ProfileDetails")
);

const PdfProfile = React.lazy(() => import("../../Profiles/Pages/PdfProfile"));
const BuyCredits = React.lazy(() => import("../../Payments/Pages/BuyCredits"));

const PaymentFailed = React.lazy(() =>
  import("../../Payments/Pages/PaymentFailed")
);

const PaymentSuccess = React.lazy(() =>
  import("../../Payments/Pages/PaymentSuccess")
);

const MyAccount = React.lazy(() => import("../../Accounts/MyAccount"));

const ChangePassword = React.lazy(() =>
  import("../../Accounts/ChangePassword")
);

function MainContent() {
  return (
    <Col lg={11}>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/search" element={<Search />} />
          <Route exact path="/competencies" element={<Competencies />} />
          <Route exact path="/new-competency" element={<NewCompetency />} />
          <Route exact path="/competencies/:cid" element={<EditCompetency />} />
          <Route
            exact
            path="/competencies/delete/:cid"
            element={<DeleteCompetency />}
          />
          <Route exact path="/behaviors" element={<Behaviors />} />
          <Route exact path="/new-behavior" element={<NewBehavior />} />
          <Route exact path="/behaviors/:bid" element={<EditBehavior />} />
          <Route
            exact
            path="/behaviors/delete/:bid"
            element={<DeleteBehavior />}
          />
          <Route exact path="/profiles" element={<Profiles />} />
          <Route exact path="/new-profile" element={<NewProfile />} />
          <Route exact path="/profiles/:pid" element={<EditProfile />} />
          <Route
            exact
            path="/profiles/indicators/:pid"
            element={<AddIndicators />}
          />
          <Route
            exact
            path="/profiles/details/:pid"
            element={<ProfileDetails />}
          />
          <Route exact path="/profiles/pdf/:pid" element={<PdfProfile />} />
          <Route exact path="/payments" element={<BuyCredits />} />
          <Route exact path="/my-account" element={<MyAccount />} />
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route
            exact
            path="/payment-processing"
            element={<PaymentSuccess />}
          />
          <Route exact path="/payment-failed" element={<PaymentFailed />} />
        </Routes>
      </Suspense>
    </Col>
  );
}

export default MainContent;
