import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Logo from "../Static/Images/Sphere_logo_login.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleFirstNameChange = (evt) => {
    setFirstName(evt.target.value);
  };

  const handleLastNameChange = (evt) => {
    setLastName(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/accounts/`,
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
        },
      });
      toast("Cuenta creada exitosamente");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center">
        <Col lg={6} className="signup">
          <ToastContainer />
          <Form>
            <Form.Group className="mb-3 center">
              <Image src={Logo} alt="Sphere logo" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Nombre(s)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresar nombre(s)"
                onChange={handleFirstNameChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Apellido(s)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresar apellido(s)"
                onChange={handleLastNameChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingresar email"
                onChange={handleEmailChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Ingresar contraseña"
                onChange={handlePasswordChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Button
                variant="primary"
                style={{ marginRight: 10 }}
                onClick={handleSubmit}
              >
                Crear cuenta
              </Button>
              <NavLink to="/login">
                <Button variant="danger">Cancelar</Button>
              </NavLink>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Signup;
