import React, { useState, useContext } from "react";
import AuthContext from "../Context/Authcontext";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Logo from "../Static/Images/Sphere_logo_login.png";
import Document from "../Static/Images/profile-document.png";
import Areas from "../Static/Images/areas.png";
import { useNavigate, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { signin } = useContext(AuthContext);

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/login`,
        {
          email: email,
          password: password,
        }
      );
      if (response.data.success) {
        signin(response.data.userId, response.data.token, response.data.role);
      }
    } catch (error) {
      console.log(error);
      toast(`${error.response.data.message}`);
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center login-container">
        <Col lg={5} className="mb-3">
          <Image src={Logo} fluid className="mb-3" />
          <h1>Menos tiempo desarrollando, más tiempo implementando</h1>
          <h5>Perfiles de puesto para consulta.</h5>
        </Col>
        <Col lg={5}>
          <Col className="login">
            <Col className="center mb-3">
              <ToastContainer />
            </Col>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingresa tu email"
                  onChange={handleEmailChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Ingresa tu contraseña"
                  onChange={handlePasswordChange}
                />
              </Form.Group>
              <Form.Group className="mb-3 d-grid gap-2">
                <Button variant="primary" onClick={handleSubmit}>
                  Log In
                </Button>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col lg={6}>
                    <NavLink to="/signup">Crea tu cuenta</NavLink>
                  </Col>
                  <Col lg={6} className="right">
                    <NavLink to="/forgot-password">
                      ¿Olvidaste tu contraseña?
                    </NavLink>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Col>
      </Row>
      <Row className="justify-content-center login-banner">
        <Col lg={7}>
          <h4 className="center">
            Bases sólidas para desarrollar, actualizar o hacer benchmarking de
            tus perfiles de puesto.
          </h4>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center mb-5 mt-5">
        <Col lg={4}>
          <Image src={Document} fluid style={{ maxHeight: 400 }} />
        </Col>
        <Col lg={4}>
          <p>
            A través de nuestra plataforma de perfiles
            <strong> tienes acceso a:</strong>
          </p>
          <p>
            <ul className="profile-features">
              <li>Mas de 100 perfiles de puesto.</li>
              <li>Perfiles basados en información real de empresas.</li>
              <li>Perfiles actualizados a la realidad del ahora.</li>
              <li>
                Habilidades blandas y comportamientos necesarios para ocupar el
                puesto.
              </li>
              <li>
                Indicadores de desempeño para medir el rendimiento del personal.
              </li>
              <li>Objetivos generales del puesto.</li>
              <li>
                Parámetros para medir estratégicamente el crecimiento del
                personal.
              </li>
              <li>Descarga del perfil en versión PDF</li>
            </ul>
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col lg={4}>
          <p>
            Las empresas y organizaciones realmente efectivas cuentan con
            claridad desde sus cimientos para poder dar soporte a sus
            estrategias e iniciativas de desarrollo y crecimiento.
          </p>
          <p>
            Si estás buscando desarrollar los perfiles de puesto para tu
            organización,{" "}
            <strong>
              no es necesario que inviertas incontables horas de esfuerzo
              tratando de reinventar el hilo negro. Estamos aquí para hacer tu
              vida más fácil
            </strong>
            . Con nuestra plataforma puedes descargar los perfiles que necesitas
            y tomarlos como una base sólida.
          </p>
          <p>
            Tendrás las{" "}
            <strong>
              {" "}
              bases para correr evaluaciones de talento y desempeño basadas en
              parámetros reales de la industria actual
            </strong>
            , abriéndote así todo un nuevo panorama para la toma de decisiones
            estratégicas . Desde la contratación del personal adecuado, hasta la
            evaluación del personal actual para basar decisiones de promoción,
            movimiento y destitución, todo basado en parámetros validados por
            empresas reales.
          </p>
        </Col>
        <Col lg={4}>
          <Image src={Areas} fluid style={{ maxHeight: 400 }} />
        </Col>
      </Row>

      <Row className="justify-content-center align-items-center footer-banner">
        <Col lg={5}>
          <h3 className="center">
            Las bases para medir y evaluar el talento y el desempeño del
            personal para llevar a la organización al siguiente nivel.
          </h3>
        </Col>
        <Col lg={3} className="center">
          <Button
            variant="primary"
            size="lg"
            onClick={() => navigate("/signup")}
          >
            Crear mi cuenta
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center footer">
        <Col lg={8} className="center">
          <p>Sphere Model | 2022</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
